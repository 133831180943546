import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  AnimatePresence,
  useAnimation,
  useInView,
} from "framer-motion";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import Wave from "../components/ui/shader/wave";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "../assets/prism-vs.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.js";

const FeatureCard = ({ title, description }) => (
  <article className="hover:animate-background  rounded-xl bg-gradient-to-r from-teal-500 to-blue-700 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
    <div className="rounded-[10px] bg-gray-900 p-6 grid grid-rows-[auto_1fr] text-white h-full">
      <h3 className="text-lg font-medium">{title}</h3>
      <p className="mt-4 text-gray-400">{description}</p>
    </div>
  </article>
);

const CodeBlock = ({ code }) => (
  <pre className="language-python line-numbers rainbow-braces">
    <code className="language-python">{code}</code>
  </pre>
);

const DysonPage = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Initialize a stack to keep track of bracket levels
    const levels = [];

    Prism.hooks.add("wrap", function (env) {
      if (env.type === "punctuation" && /[\[\]{}()]/.test(env.content)) {
        let level = 0;

        if (env.content === "[" || env.content === "{" || env.content === "(") {
          // Opening bracket: push to stack
          levels.push(env.content);
          level = levels.length;
        } else if (
          env.content === "]" ||
          env.content === "}" ||
          env.content === ")"
        ) {
          // Closing bracket: use current level before popping
          level = levels.length;
          levels.pop();
        }

        level = level % 6; // Cycle through 6 colors
        env.classes.push("bracket-level-" + level);
      }
    });

    // Highlight all code blocks
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const code1 = `import torch
import time

# Define matrix sizes
M, N, K = ...

# Check if GPU is available and set device
device = torch.device("cuda" if torch.cuda.is_available() else "cpu")

# Create random matrices and move them to the GPU
A = torch.randn(M, K, device=device)
B = torch.randn(K, N, device=device)

# Warm-up for GPU
_ = torch.matmul(A, B)  # This will ensure the GPU is warmed up

# Measure GPU performance
start_time_hardware = time.time()
C_gpu = torch.matmul(A, B)
torch.cuda.synchronize()  # Wait for all operations to complete
end_time_hardware = time.time()
hardware_time = end_time_hardware - start_time_hardware

# Display the performance results
print(f"GPU Time: {hardware_time:.6f} seconds")`;

  const code2 = `import dyson

# Define matrix sizes
M, N, K = ...

A = dyson.random.rand(M, K).astype(dyson.float32) 
B = dyson.random.rand(K, N).astype(dyson.float32)

# Route to the best hardware (e.g., TPU)
hardware = dyson.route_hardware(dyson.matmul, mode="high-performance", judge=5, run_type="log", complexity="high", precision="high")

# Compile the function on the selected hardware (e.g., TPU)
matmul_jit = dyson.run(matmul_func, hardware=hardware)

_ = matmul_jit(A, B) # Warm-up for the selected hardware

# Measure Dyson performance
start_time_hardware = dyson.time()
C_dyson = matmul_jit(A, B)
end_time_hardware = dyson.time()
hardware_time = end_time_hardware - start_time_hardware

# Display the performance results
print(f"Dyson Time: {hardware_time:.6f} seconds")
`;

  const TerminalOutput = ({ command, output, delay }) => {
    const [typedCommand, setTypedCommand] = useState("");
    const [showOutput, setShowOutput] = useState(false);
    const currentCharIndex = useRef(0);
    const typingTimeout = useRef(null);
    const outputTimeout = useRef(null);

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-50px" });

    useEffect(() => {
      if (isInView) {
        const typingSpeed = 125;

        const typeChar = () => {
          if (currentCharIndex.current < command.length) {
            const nextChar = command.charAt(currentCharIndex.current);
            setTypedCommand((prev) => prev + nextChar);
            currentCharIndex.current += 1;
            typingTimeout.current = setTimeout(typeChar, typingSpeed);
          } else {
            // After typing is complete, start timer to show output
            outputTimeout.current = setTimeout(() => {
              setShowOutput(true);
            }, delay);
          }
        };

        typeChar();
      }

      return () => {
        if (typingTimeout.current) clearTimeout(typingTimeout.current);
        if (outputTimeout.current) clearTimeout(outputTimeout.current);
      };
    }, [isInView, command, delay]);

    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="terminal bg-gray-900 text-white rounded-md p-4 w-full shadow-lg font-mono text-base"
        style={{ minHeight: "150px" }} // Adjusted minHeight
      >
        <div className="terminal-content">
          <p className="terminal-line">
            <span className="text-green-500">user@dyson:~$ </span>{" "}
            {typedCommand}
            {!showOutput && <span className="animate-pulse">|</span>}
          </p>
          {showOutput && (
            <p className="terminal-output text-gray-300 mt-2">{output}</p>
          )}
        </div>
      </motion.div>
    );
  };

  const CodeTerminalContainer = ({
    title,
    code,
    terminalCommand,
    terminalOutput,
    delay,
  }) => (
    <div className="code-terminal-container relative bg-gray-800 rounded-lg overflow-hidden shadow-lg max-w-full">
      {/* Header */}
      <div className="header bg-gray-900 px-4 py-2 flex items-center justify-between z-10">
        <div className="flex items-center">
          <div className="dots flex space-x-1.5">
            <span className="w-3 h-3 bg-red-500 rounded-full"></span>
            <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
            <span className="w-3 h-3 bg-green-500 rounded-full"></span>
          </div>
          <div className="ml-3 text-gray-400 text-sm">{title}</div>
        </div>
        {/* Optional Badge */}
        <div className="badge bg-blue-600 text-white text-xs px-2 py-1 rounded">
          {title.includes("GPU") ? "Standard" : "Optimized"}
        </div>
      </div>

      {/* Code Block */}
      <div className="code-block p-4">
        <CodeBlock code={code} />
      </div>

      {/* Terminal Output Overlay */}
      <div className="terminal-overlay absolute bottom-0 left-0 right-0 bg-gray-900 p-4">
        <TerminalOutput
          command={terminalCommand}
          output={terminalOutput}
          delay={delay}
        />
      </div>
    </div>
  );

  return (
    <div className="dyson-page bg-black text-white">
      <header className="header-section relative text-center pt-32 pb-20 bg-gradient-to-r from-black via-decoration-[#ff233c] to-black text-red-800">
        <div className="absolute inset-0 z-0 pointer-events-none">
          <Wave />
        </div>

        <motion.h1
          className="text-6xl mt-5 font-bold relative z-10"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Dyson
        </motion.h1>
        <motion.p
          className="mt-10 text-xl text-white px-4 relative z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          Intelligent, Dynamic Routing for Computational Efficiency
        </motion.p>
      </header>

      <section
        id="key-features"
        className="key-features py-10 bg-black container mx-auto px-4"
      >
        <h2 className="text-center text-4xl font-bold mb-4 text-blue-500 relative flex items-center justify-center">
          <span className="flex-grow max-w-lg border-t border-red-600"></span>
          <span className="mx-4 text-teal-500">Key Features</span>
          <span className="flex-grow max-w-lg border-t border-red-600"></span>
        </h2>
        <div className="features-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-20">
          <FeatureCard
            title="LLM-Powered Decision Making"
            description="Dyson uses a state-of-the-art LLM (MOE) to evaluate complexity, precision, and the nature of each input."
          />
          <FeatureCard
            title="Modular SDK with Efficient Mathematical Kernels"
            description="A comprehensive library of optimized mathematical functions, implemented with maximum efficiency."
          />
          <FeatureCard
            title="Integration with Existing Workflows"
            description="Dyson’s modular SDK fits into existing frameworks like Python, C++, and Bash."
          />
        </div>
      </section>

      <section className="video-section py-10 bg-black text-white container mx-auto px-4">
        <div className="video-container flex p-10 justify-center border md:border-1 border-red-600 rounded-lg max-w-md md:max-w-lg lg:max-w-4xl mx-auto">
          <motion.video
            ref={videoRef}
            className="w-full"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            muted
            loop
            controls={false}
            preload="auto"
            src={require("../assets/video/dyson_animation_1.mp4")}
          />
        </div>

        <h2 className="text-center text-4xl mb-12 text-blue-500 relative flex items-center justify-center">
          <h2 className="text-center mx-4 mt-16 text-gray-400 text-lg max-w-4xl leading-relaxed">
            Dyson supports a broad spectrum of hardware, from traditional CPUs
            and GPUs to specialized accelerators like TPUs and FPGAs, as well as
            emerging technologies like quantum computers.
          </h2>
        </h2>
      </section>

      <section className="compare-section py-16 bg-black container mx-auto px-4">
        <h2 className="text-center text-4xl font-bold mb-12 relative flex items-center justify-center">
          <span className="flex-grow max-w-40 border-t border-red-600"></span>
          <span className="text-center text-4xl mx-5">
            With <span className="text-blue-400">Dyson</span>, never worry about{" "}
            <span className="text-blue-400">what hardware to use</span>!
          </span>
          <span className="flex-grow max-w-40 border-t border-red-600"></span>
        </h2>

        {/* Add a wrapper div for flex and gap */}
        <div className="flex flex-col md:flex-row justify-center gap-12">
          <CodeTerminalContainer
            title="GPU Example"
            code={code1}
            terminalCommand="python main.py"
            terminalOutput="GPU Time: 0.236953 seconds"
            delay={2000}
          />

          <CodeTerminalContainer
            title="Dyson Example"
            code={code2}
            terminalCommand="python main.py"
            terminalOutput="Dyson Time: 0.049588 seconds"
            delay={400}
          />
        </div>

        <h2 className="text-center text-4xl mb-12 text-blue-500 relative flex items-center justify-center">
          <h2 className="text-center mx-4 mt-16 text-gray-400 text-lg max-w-4xl leading-relaxed">
            Dyson routes you to TPUv2 - easy to obtain, low cost, maximum
            efficiency.
          </h2>
        </h2>
      </section>

      <section className="pricing-section text-center py-10 bg-black text-white">
        <motion.h1
          className="text-6xl font-bold mb-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-center text-4xl font-bold mb-12 relative flex items-center justify-center">
            <span className="flex-grow max-w-[430px] border-t border-red-600"></span>
            <h2 className="text-center text-4xl mx-5">
              <span className="text-blue-400">Ready to Get Started</span>?
            </h2>
            <span className="flex-grow max-w-[430px] border-t border-red-600"></span>
          </h2>
        </motion.h1>

        <motion.div
          className=""
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          <Link
            to="/pricing"
            className="scroll-down mt-10 inline-block font-semibold text-2xl bg-white text-cyan-900 py-2 px-6 rounded-lg relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-red-700  before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
          >
            <span className="relative z-10">View Pricing</span>
          </Link>
        </motion.div>
      </section>

      <Accordion />
    </div>
  );
};

const faqs = [
  {
    question: "How does Dyson work?",
    answer:
      "Dyson analyzes your computations as mathematical objects using a chain of Large Language Models (LLMs) and dynamically routes them to the best-suited hardware—such as CPUs, GPUs, TPUs, FPGAs, etc.—to maximize performance, minimize costs, or meet any other metrics specified by the user!",
  },
  {
    question: "Is Dyson compatible with my existing infrastructure?",
    answer:
      "Yes, Dyson is designed to integrate seamlessly. Just pip install dyson and use it as you like!",
  },
  {
    question: "How does Dyson ensure data security and privacy?",
    answer:
      "Dyson adheres to industry-standard security protocols and compliance regulations to ensure your data is safe and private throughout all computations.",
  },
  {
    question: "How much does it cost to use Dyson?",
    answer:
      "Dyson offers a range of pricing plans, from free access for individuals and startups to tailored enterprise packages. Pricing is based on savings achieved, with different rates for different tiers.",
  },
];

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="accordion-section">
      <div className="bg-black">
        <section className="max-w-5xl mx-auto py-10 sm:py-20">
          <div className="flex items-center justify-center flex-col gap-y-2 py-5">
            <h2 className="text-2xl text-center md:text-3xl lg:text-4xl font-bold text-white">
              Frequently Asked Questions
            </h2>
            <p className="text-lg font-medium text-teal-500/70">
              Questions related to Dyson
            </p>
          </div>
          <div className="w-full px-7 md:px-10 xl:px-2 py-4">
            <div className="mx-auto w-full max-w-5xl border border-black rounded-lg bg-transparent">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`border-b border-teal-500/20 ${
                    activeIndex === index ? "bg-black/30" : ""
                  }`}
                >
                  <button
                    className="question-btn bg-teal-900 hover:text-blue-400 flex w-full items-start gap-x-5 justify-between rounded-lg text-left text-lg font-bold focus:outline-none px-5 py-3 mb-2"
                    onClick={() => toggleAnswer(index)}
                  >
                    <span>{faq.question}</span>
                    <svg
                      className={`mt-1.5 md:mt-0 flex-shrink-0 h-5 w-5 text-teal-500 transform transition-transform duration-300 ${
                        activeIndex === index ? "rotate-180" : ""
                      }`}
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                    </svg>
                  </button>
                  <AnimatePresence>
                    {activeIndex === index && (
                      <motion.div
                        className="answer overflow-hidden text-sm lg:text-base text-teal-400"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.4 }}
                      >
                        <div className="pt-2 pb-5 px-5">{faq.answer}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default DysonPage;
