import React, { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const APIKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [copyMessage, setCopyMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alias, setAlias] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [isApiKeyGenerated, setIsApiKeyGenerated] = useState(false);
  const [pricingModel, setPricingModel] = useState(null);
  const [overviewData, setOverviewData] = useState({
    requests: 0,
    credits: 0,
    computeCost: 0,
  });
  const navigate = useNavigate();

  const fetchApiKeys = async () => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        "https://backend-service-775365341254.us-central1.run.app/list-api-keys",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setApiKeys(data.api_keys);
      setEmail(data.email);
      setError(null);

      setOverviewData({
        requests: data.total_requests,
        credits: data.total_credits,
        computeCost: data.total_compute_cost,
      });
    } catch (error) {
      setError(error.message);
      console.error("Error fetching API keys:", error);
    }
  };

  useEffect(() => {
    fetchApiKeys();
    const selectedPricingModel = localStorage.getItem("selectedPricingModel");
    if (selectedPricingModel) {
      setPricingModel(selectedPricingModel);
      setIsModalOpen(true);
    }
  }, []);

  const generateApiKey = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!alias || !pricingModel || !accessToken) {
      setError("Missing required information");
      return;
    }

    try {
      const response = await fetch(
        "https://backend-service-775365341254.us-central1.run.app/generate-api-key",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ alias, pricing_model: pricingModel }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to generate API key. Status: ${response.status}`
        );
      }

      const data = await response.json();
      setApiKey(data.api_key);
      setIsApiKeyGenerated(true);
      setError("");
      localStorage.removeItem("selectedPricingModel");
      setPricingModel(null);
    } catch (err) {
      setError("Error generating API key");
      console.error(err);
    }
  };

  const copyToClipboard = (key) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        setCopyMessage("API key copied to clipboard!");
        setTimeout(() => setCopyMessage(""), 3000);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <div className="p-8 rounded-xl shadow-lg text-gray-200">
      {/* Overview Section */}
      <div className="bg-gray-900 p-8 rounded-xl text-center shadow-lg transition-all text-gray-100 mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div className="bg-gray-700 p-4 rounded-lg shadow-lg transition-transform hover:scale-[1.02]">
            <p className="text-lg font-medium text-gray-200">Total Requests</p>
            <p className="text-cyan-300 text-3xl font-semibold">
              00{overviewData.requests}
            </p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg shadow-lg transition-transform hover:scale-[1.02]">
            <p className="text-lg font-medium text-gray-200">Credits</p>
            <p className="text-cyan-300 text-3xl font-semibold">
              $ 00.00{overviewData.credits}
            </p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg shadow-lg transition-transform hover:scale-[1.02]">
            <p className="text-lg font-medium text-gray-200">Compute Cost</p>
            <p className="text-cyan-300 text-3xl font-semibold">
              $ 00.00{overviewData.computeCost}
            </p>
          </div>
        </div>
      </div>

      {/* Modal for generating API key */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-cyan-950 p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-xl text-cyan-300 font-bold mb-4">API Name</h2>
            <input
              type="text"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder="Give a name for your API"
              className="w-96 p-2 border text-red-600 bg-black border-gray-200 rounded-lg"
            />
            {isApiKeyGenerated ? (
              <div>
                <h2 className="mt-4 text-xl font-semibold text-cyan-300">
                  API Key
                </h2>
                <input
                  type="text"
                  value={apiKey}
                  readOnly
                  className="w-full p-2 mt-2 border text-cyan-600 border-gray-300 bg-black rounded"
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(apiKey);
                    setIsModalOpen(false);
                  }}
                  className="px-4 py-2 mt-4 bg-teal-600 text-red-600 rounded"
                >
                  Copy API Key
                </button>
              </div>
            ) : (
              <>
                <button
                  onClick={generateApiKey}
                  className="px-4 py-2 mt-4 font-semibold bg-teal-600 text-black rounded mx-auto block"
                >
                  Generate API Key
                </button>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 mt-4 bg-red-600 text-white rounded mx-auto block"
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Scrollable API Keys Section */}
      <div className="flex justify-end mb-6 gap-4">
        {copyMessage && (
          <div className="p-2 text-green-700 font-semibold">{copyMessage}</div>
        )}
        <button
          onClick={fetchApiKeys}
          className="px-5 py-3 bg-gray-800 ring-white text-white ring-2 rounded-lg hover:text-red-500 hover:ring-red-500 hover:bg-gray-800"
        >
          Refresh
        </button>
        <a
          href="/#/pricing"
          target=""
          rel="noopener noreferrer"
          className="px-5 py-3 bg-blue-950 ring-red-900 text-red-500 ring-2 rounded-lg hover:bg-gray-800"
        >
          Create API Key
        </a>
      </div>

      {apiKeys.length === 0 ? (
        <p className="font-semibold text-red-500 bg-gray-800 text-center ring-4 ring-blue-900 rounded-md px-4 py-3">
          You have no API keys.{" "}
          <a href="/#/pricing" className="text-gray-400">
            Get your first API key →
          </a>
        </p>
      ) : (
        <div className="max-h-96 px-2 overflow-y-auto ring rounded-lg ring-2 ring-cyan-600">
          {apiKeys.map((key, index) => (
            <div
              key={index}
              className="flex items-center gap-2 justify-between bg-gray-700 p-2 rounded-lg shadow-lg my-3 ring-2 ring-gray-700"
            >
              <input
                type="text"
                className="w-full px-4 py-2 bg-black text-red-500 rounded-lg focus:outline-none ring-2 ring-gray-700 hover:ring-2"
                value={key.alias}
                readOnly
              />
              <div className="w-32 font-poppins font-semibold flex items-center px-3 p-2 text-cyan-700 bg-gray-300 rounded-lg">
                {key.pricing_model}
              </div>
              <button
                onClick={() => copyToClipboard(key.api_key)}
                className="flex items-center px-4 py-3 bg-gray-600 text-black rounded-lg hover:bg-gray-500"
              >
                <FaCopy className="mr-1" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default APIKeys;
