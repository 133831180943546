import React from 'react';

const Billing = () => {
  return (
    <div className="bg-gray-900 p-8 rounded-xl shadow-lg transition-all text-gray-100">
      <h2 className="text-white text-2xl mb-2">Manage Payment Methods</h2>
      <p className="text-gray-400 mb-8 text-lg leading-relaxed">
        CrossGL offers various payment methods for Dyson. Please select your preferred method and proceed.
      </p>
      <a
        href="https://payment.example.com"
        target="_blank"
        rel="noopener noreferrer"
        className="px-5 py-3 border border-cyan-500 text-cyan-500 rounded-lg hover:border-cyan-500 hover:bg-teal-900 hover:text-cyan-500 transition-all"
      >
        Manage Payment
      </a>
    </div>
  );
};

export default Billing;
