import { Link } from "react-router-dom";
import { useState } from "react";

const Footer = ({ scrollToSection, refs }) => {
  const socialLinks = [
    { href: "https://discord.com/invite/uyRQKXhcyW", img: "/img/disc.webp", alt: "Discord" },
    { href: "https://x.com/crossGL_", img: "/img/x.webp", alt: "X" },
    { href: "https://www.youtube.com/@CrossGL-i9q", img: "/img/yt.png", alt: "YouTube" },
    { href: "https://www.linkedin.com/company/crossgl/", img: "/img/linkedin.webp", alt: "LinkedIn" },
  ];

  const [hoverSales, setHoverSales] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);

  const [showProducts, setShowProducts] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const handleLinkClick = (ref) => {
    if (ref.current) {
      scrollToSection(ref);
    }
  };

  return (
    <footer className="bg-transparent text-teal-600 py-8">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="text-center py-8">
          <h3 className="text-2xl font-semibold mb-4 text-white">Want to learn more? Reach out!</h3>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <Link
              to="/contact"
              className="border-2 border-teal-600 text-teal-600 px-4 py-2 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out"
            >
              Book a call
            </Link>

            <a
              href="mailto:sales@crossgl.net"
              className="border-2 border-teal-600 text-teal-600 px-4 py-2 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out relative overflow-hidden flip"
              onMouseEnter={() => setHoverSales(true)}
              onMouseLeave={() => setHoverSales(false)}
            >
              {hoverSales ? "sales@crossgl.net" : "Sales Inquiry"}
            </a>

            <a
              href="mailto:contact@crossgl.net"
              className="border-2 border-teal-600 text-teal-600 px-4 py-2 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out relative overflow-hidden flip"
              onMouseEnter={() => setHoverInfo(true)}
              onMouseLeave={() => setHoverInfo(false)}
            >
              {hoverInfo ? "contact@crossgl.net" : "General Inquiry"}
            </a>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between gap-12">
          {/* Left Column */}
          <div className="flex flex-col md:flex-row gap-12">
            {/* Products */}
            <div className="flex-1">
              <button
                className="font-bold mb-4 text-white w-full text-left md:text-base"
                onClick={() => setShowProducts(!showProducts)}
              >
                <span className="block md:hidden">{showProducts ? "Products" : "Products"}</span>
                <span className="hidden md:block">Products</span>
              </button>
              <ul className={`space-y-2 ${showProducts ? "" : "hidden md:block"}`}>
                <li>
                  <Link to="/dyson" className="hover:underline text-teal-600">Dyson</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={() => handleLinkClick(refs.Translatorref)}
                    className="hover:underline text-teal-600 cursor-pointer"
                  >
                    CrossTL
                  </Link>
                </li>
                <li>
                  <Link to="/careers" className="hover:underline text-teal-600">CrossVL</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={() => handleLinkClick(refs.Compilerref)}
                    className="hover:underline text-teal-600 cursor-pointer"
                  >
                    CrossGL Compiler
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources */}
            <div className="flex-1">
              <button
                className="font-bold mb-4 text-white w-full text-left md:text-base"
                onClick={() => setShowResources(!showResources)}
              >
                <span className="block md:hidden">{showResources ? "Resources" : "Resources"}</span>
                <span className="hidden md:block">Resources</span>
              </button>
              <ul className={`space-y-2 ${showResources ? "" : "hidden md:block"}`}>
                <li>
                  <Link to="/blogs" className="hover:underline text-teal-600">Blogs</Link>
                </li>
                <li>
                  <Link to="https://crossgl.github.io/crossgl-docs/" className="hover:underline text-teal-600">Docs</Link>
                </li>
                <li>
                  <Link to="https://github.com/CrossGL" className="hover:underline text-teal-600">Github</Link>
                </li>
              </ul>
            </div>

            {/* About Us */}
            <div className="flex-1">
              <button
                className="font-bold mb-4 text-white w-full text-left md:text-base"
                onClick={() => setShowAbout(!showAbout)}
              >
                <span className="block md:hidden">{showAbout ? "About Us" : "About Us"}</span>
                <span className="hidden md:block">About Us</span>
              </button>
              <ul className={`space-y-2 ${showAbout ? "" : "hidden md:block"}`}>
                <li>
                  <Link to="/signup" className="hover:underline text-teal-600">SignUp</Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:underline text-teal-600">Contact Us</Link>
                </li>
                <li>
                  <Link to="/pricing" className="hover:underline text-teal-600">Pricing</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column */}
          <div className="flex flex-col items-center space-y-6">
            <Link to="/" className="text-[35px] font-bold mb-2 gap-2 text-white flex items-center">
              <img src="../../logo.png" alt="CrossGL Logo" className="w-10 h-10" /> CrossGL
            </Link>
            <div className="flex space-x-4">
              {socialLinks.map(({ href, img, alt }) => (
                <a
                  key={href}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  <img src={img} alt={alt} className="w-12 h-12 p-1 border-2 rounded-lg border-teal-600" />
                </a>
              ))}
            </div>
            <div className="mt-4 space-x-4 text-sm">
              <Link to="/privacy" className="hover:underline text-teal-600">Privacy Policy</Link>
              <Link to="/terms" className="hover:underline text-teal-600">Terms & Conditions</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
