import React, { useState, useEffect } from "react";

const DysonCalculator = () => {
  // State for slider value, burn rate, and complexity
  const [sliderValue, setSliderValue] = useState(10); // Start at 10 for max performance
  const [burnRate, setBurnRate] = useState(0.02); // Initial burn rate
  const [complexity, setComplexity] = useState("medium"); // Initial complexity

  // Fixed metrics
  const numberOfUsers = 200000;

  // Calculate performance and savings based on slider value and selected variables
  const calculatePerformanceAndSavings = (value) => {
    // Set initial performance and savings based on slider value
    let basePerformance = 100; // Start at 100% for max slider
    let baseSavings = 0; // Start at 0% savings for max slider

    // Adjust based on slider value
    if (value < 10) {
      // Linear interpolation between slider values
      basePerformance = 60 + (value - 1) * 4; // Interpolate from 60% to 100%
      baseSavings = 20 - (value - 1) * 2; // Interpolate from 20% to 0%
    }

    // Adjust based on complexity
    if (complexity === "easy") {
      baseSavings = Math.min(20, baseSavings + 5); // Increase savings by 5%
    } else if (complexity === "hard") {
      baseSavings = Math.max(0, baseSavings - 15); // Decrease savings by 15%
    }

    // Adjust based on burn rate
    const burnRateAdjustment = (burnRate - 0.02) / 0.02; // Calculate how many increments above 0.02
    basePerformance += burnRateAdjustment * 20; // Increase performance by 20% for each 0.02 increment
    baseSavings -= burnRateAdjustment * 2; // Decrease savings by 2% for each 0.02 increment

    // Ensure performance does not exceed 100% or drop below 60%
    basePerformance = Math.min(100, Math.max(60, basePerformance));
    // Ensure savings do not exceed 20% or drop below 0%
    baseSavings = Math.min(20, Math.max(0, baseSavings));

    return { performance: basePerformance, savings: baseSavings };
  };

  const { performance, savings } = calculatePerformanceAndSavings(sliderValue);

  return (
    <div className=" h-96 flex flex-col items-center justify-center p-8 rounded-lg border-4 border-cyan-700 bg-black w-[350px] mx-auto">
      <h2 className="text-red-600 text-xl font-bold mb-4">Dyson Calculator</h2>

      <input
        type="range"
        min="1"
        max="10"
        value={sliderValue}
        onChange={(e) => setSliderValue(Number(e.target.value))}
        className="slider w-full mb-4"
      />

      <div className="mt-4 text-center text-teal-400">
        <p className="text-lg">Max Performance: {performance}%</p>
        <p className="text-lg">Savings: {savings}%</p>
      </div>

      <div className="mt-6 text-left text-white w-full">
        <div className="metric mb-2 flex justify-between">
          <p className="text-lg text-cyan-500">Burn Rate :</p>
          <select 
            value={burnRate} 
            onChange={(e) => setBurnRate(parseFloat(e.target.value))}
            className="custom-dropdown border-red-600"
          >
            <option value={0.02}>0.02 $</option>
            <option value={0.04}>0.04 $</option>
            <option value={0.06}>0.06 $</option>
          </select>
        </div>

        <div className="metric mb-2 flex justify-between">
          <p className="text-lg text-cyan-500">Complexity :</p>
          <select 
            value={complexity} 
            onChange={(e) => setComplexity(e.target.value)}
            className="custom-dropdown"
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>

        
      </div>
    </div>
  );
};

export default DysonCalculator;
