import React, { useState } from "react";
import { motion } from "framer-motion";
import CurvyBackground from "../components/ui/Curvy_line";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import lineGraphLottie from "../assets/line_graph.json";
import DysonCalculator from "../components/ui/DysonCalculator";
import DysonLogo from "../assets/image.png";

const Hero = () => {
  const [isCrossGLAnimated, setIsCrossGLAnimated] = useState(false);

  const variants = {
    hidden: { x: 0, opacity: 0 },
    visible: {
      x: "-210%",
      opacity: 1,
      transition: {
        opacity: { delay: 1, duration: 1 },
        delay: 1.3,
        type: "tween",
        duration: 0.75,
        when: "beforeChildren",
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 1.7,
        type: "tween",
        duration: 0.7,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.5,
        duration: 0.8,
      },
    }),
  };

  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lineGraphLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="relative font-poppins w-full min-h-screen">
      <div className="absolute inset-0 w-full h-screen overflow-hidden">
        <CurvyBackground
          rotation={[-Math.PI / 2, 0, Math.PI / 5]}
          position={[0, 0, 0]}
          color="#00ffff"
          className="w-full h-full"
        />
      </div>

      <div className="relative flex flex-col items-center justify-center w-full h-screen text-white">
        <motion.div className="flex flex-row items-center justify-center w-screen space-x-16 sm:space-x-10 lg:space-x-24 xl:space-x-24">
          <motion.img
            src="./logo.png"
            variants={variants}
            className="w-16 xs:w-16 sm:w-20 lg:w-24 xl:w-24 absolute"
            initial="hidden"
            animate="visible"
          />
          <motion.div>
            <motion.span
              className="text-6xl xs:text-5xl sm:text-6xl lg:text-8xl xl:text-8xl font-semibold"
              variants={letterVariants}
              initial="hidden"
              animate="visible"
              style={{ height: "100%" }}
              onAnimationComplete={() => setIsCrossGLAnimated(true)}
            >
              CrossGL
            </motion.span>
          </motion.div>
        </motion.div>

        <div
          className="w-screen flex items-center justify-center"
          style={{ minHeight: "100px" }}
        >
          {isCrossGLAnimated && (
            <motion.div
              className="w-screen flex items-center justify-center mt-4 sm:mt-4 lg:mt-8 xl:mt-8"
              initial="hidden"
              animate="visible"
            >
              <motion.div
                className="flex space-x-4"
                initial="hidden"
                animate="visible"
              >
                <motion.span
                  variants={textVariants}
                  custom={0}
                  className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
                >
                  One Platform.
                </motion.span>
                <motion.span
                  variants={textVariants}
                  custom={1}
                  className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
                >
                  Any Hardware.
                </motion.span>
                <motion.span
                  variants={textVariants}
                  custom={2}
                  className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
                >
                  Infinite Possibilities.
                </motion.span>
              </motion.div>
            </motion.div>
          )}
        </div>
      </div>

      <motion.div
        className="flex flex-col bg-[#111111] items-center justify-center py-10"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: false, amount: 0.5 }}
      >
        <img src={DysonLogo} alt="Dyson Logo" className="w-32 h-auto mb-4" />
        <h2 className="text-2xl sm:text-3xl lg:text-4xl text-white font-semibold">DYSON</h2>
      </motion.div>

      <motion.div
        className="w-full h-auto flex flex-col border-2 border-teal-700 rounded-xl items-center justify-center bg-[#111111] py-20"
        variants={fadeInVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
      >
        <h2 className="text-3xl sm:text-4xl lg:text-5xl text-red-700 font-bold mb-8 sm:mb-10 lg:mb-14 text-center">
          Dyson
        </h2>

        <p className="text-base sm:text-lg lg:text-xl text-cyan-400 mb-2 text-center max-w-2xl lg:max-w-4xl px-4">
          Dyson is a dynamic, intelligent routing system for computational tasks.
        </p>
        <p className="text-base sm:text-lg lg:text-xl text-gray-400 mb-6 sm:mb-8 lg:mb-12 text-center max-w-2xl lg:max-w-3xl px-4">
          It optimizes hardware usage across GPUs, CPUs, and specialized accelerators
          like TPUs, ensuring the highest performance and cost efficiency for every task.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12 w-full max-w-6xl px-4 lg:px-8 mt-6 lg:mt-12">
          <div className="flex justify-center">
            <DysonCalculator />
          </div>

          <div className="flex flex-col items-center space-y-4 lg:space-y-8">
            <Link
              to="/dyson"
              className="mt-10 w-56 font-semibold text-center inline-block bg-cyan-300 text-cyan-950 py-2 px-6 rounded-md relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-all ease-in-out before:z-0"
            >
              <span className="relative z-10">Explore Dyson</span>
            </Link>

            <Link
              to="/dashboard"
              className="mt-10 w-56 font-semibold inline-block bg-cyan-300 text-cyan-950 py-2 px-6 rounded-md relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-all ease-in-out before:z-0"
            >
              <span className="relative z-10">Get Your Free API Key</span>
            </Link>
          </div>

          <div className="flex justify-center">
            <Lottie options={defaultOptions} height={350} width={350} />
          </div>
        </div>
      </motion.div>

      <motion.div
        className="flex flex-col items-center bg-[#111111] justify-center py-10"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: false, amount: 0.5 }}
      >
        <img src={DysonLogo} alt="Graphica Logo" className="w-32 h-auto mb-4" />
        <h2 className="text-2xl sm:text-3xl lg:text-4xl text-white font-semibold">GRAPHICA</h2>
      </motion.div>
    </div>
  );
};

export default Hero;
