import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Overview = () => {
  const [overviewData, setOverviewData] = useState({
    requests: "00",
    avgResponseCost: "$ 00.00",
    avgSavings: "$ 00.00"
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/overview');
        setOverviewData({
          requests: response.data.requests || "00",
          avgResponseCost: response.data.avgResponseCost || "00",
          avgSavings: response.data.avgSavings || "00"
        });
      } catch (error) {
        console.error('Error fetching overview data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-gray-900 p-8 rounded-xl text-center shadow-lg transition-all text-gray-100">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        {Object.entries(overviewData).map(([key, value]) => (
          <div key={key} className="bg-gray-700 p-4 rounded-lg shadow-lg transition-transform hover:scale-[1.02]">
            <p className="text-lg font-medium capitalize text-gray-200">{key.replace(/([A-Z])/g, ' $1')}</p>
            <p className="text-cyan-300 text-3xl font-semibold">{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Overview;
